import { gql, useMutation, useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { Button, Form, Popconfirm, Skeleton, Space, Typography, message } from 'antd'
import { Link, navigate } from 'gatsby-plugin-intl'
import { isNil } from 'ramda'
import React, { FC } from 'react'
import styled from 'styled-components'

import BannerForm, { BannerFormFields } from '../../../components/forms/BannerForm'
import {
  DashboardBannerQuery,
  DashboardBannerQueryVariables,
  RemoveBannerMutation,
  RemoveBannerMutationVariables,
  UpdateBannerMutation,
  UpdateBannerMutationVariables
} from '../../../types/types'
import { getFileObjectFromUrl } from '../../../utils/antd'
import { getPayloadFromFieldsValue } from './utils'

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
`

type Props = RouteComponentProps<{ id: string }>

const bannerQuery = gql`
  query DashboardBanner($id: ID!) {
    banners(id: $id) {
      id
      image
      description
      link
      active
      order
    }
  }
`

const updateBanner = gql`
  mutation UpdateBanner($id: ID!, $payload: UpdateBannersInput!) {
    updateBanners(id: $id, payload: $payload) {
      id
    }
  }
`

const removeBanner = gql`
  mutation RemoveBanner($id: ID!) {
    removeBanners(id: $id) {
      id
    }
  }
`

const BannerPage: FC<Props> = props => {
  const { id: bannerId } = props

  if (isNil(bannerId)) {
    message.error('無法取得 ID')
    return (
      <Link to='/dashboard/banners'>
        <Button>回列表</Button>
      </Link>
    )
  }

  const { data, loading, refetch } = useQuery<DashboardBannerQuery, DashboardBannerQueryVariables>(bannerQuery, {
    variables: {
      id: bannerId
    }
  })
  const [update] = useMutation<UpdateBannerMutation, UpdateBannerMutationVariables>(updateBanner)
  const [remove] = useMutation<RemoveBannerMutation, RemoveBannerMutationVariables>(removeBanner)
  const [form] = Form.useForm<BannerFormFields>()
  const bannerData = data?.banners

  if (loading) {
    return <Skeleton active />
  }
  if (isNil(bannerData)) {
    message.error('查無資料，請返回列表頁')
    return (
      <Link to='/dashboard/banners'>
        <Button>回列表</Button>
      </Link>
    )
  }

  const initialValues = {
    image: [getFileObjectFromUrl(bannerData.image || '', { uid: bannerId, name: bannerData.description })],
    description: bannerData.description,
    link: bannerData.link,
    active: bannerData.active,
    order: bannerData.order
  }

  const handleUpdate = async () => {
    try {
      const fieldsValue = await form.validateFields()
      await update({
        variables: {
          id: bannerId,
          payload: getPayloadFromFieldsValue(fieldsValue as BannerFormFields)
        },
        update: async (cache, { data }) => {
          if (data?.updateBanners) {
            message.info(`已更新橫幅 \u{1F9DA}`)
            refetch()
            navigate('/dashboard/banners')
          }
        }
      })
    } catch (e) {
      if (e.errorFields) {
        form.scrollToField(e.errorFields[0].name)
      }

      if (e.message) {
        message.error(e.message)
      }
    }
  }

  const handleRemove = async () => {
    try {
      await remove({
        variables: { id: bannerId },
        update: async (cache, { data }) => {
          if (data?.removeBanners) {
            message.info(`已刪除橫幅`)
            navigate('/dashboard/banners')
          }
        }
      })
    } catch (e) {
      if (e.message) {
        message.error(e.message)
      }
    }
  }

  return (
    <>
      <Typography.Title>編輯橫幅</Typography.Title>
      {loading || isNil(bannerData) ? (
        <Skeleton active />
      ) : (
        <>
          <BannerForm form={form} initialValues={initialValues} />
          <ButtonRow>
            <Space>
              <Button type='primary' onClick={handleUpdate}>
                更新
              </Button>
              <Link to='/dashboard/banners'>
                <Button>回列表</Button>
              </Link>
            </Space>
            <Popconfirm title='確定刪除橫幅？' onConfirm={handleRemove}>
              <Button type='primary' danger>
                刪除
              </Button>
            </Popconfirm>
          </ButtonRow>
        </>
      )}
    </>
  )
}

export default BannerPage
