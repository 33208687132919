import { gql, useQuery } from '@apollo/client'
import { Router } from '@reach/router'
import { navigate } from 'gatsby'
import React, { FC, useEffect } from 'react'

import FullScreenSpin from '../../components/FullScreenSpin'
import DashboardLayout from '../../components/Layout/DashboardLayout'
import { CheckIsLoggedInWhenInDashboardIndexQuery, CheckIsLoggedInWhenInDashboardIndexQueryVariables } from '../../types/types'
import BannerCreate from './banner/Create'
import BannerList from './banner/List'
import BannerPage from './banner/Page'
import CaseCreate from './case/Create'
import CaseList from './case/List'
import CasePage from './case/Page'
import CommentList from './comment/List'
import DoctorCreate from './doctor/Create'
import DoctorList from './doctor/List'
import DoctorPage from './doctor/Page'
import NewsCreate from './news/Create'
import NewsList from './news/List'
import NewsPage from './news/Page'

const checkIsLoggedInQuery = gql`
  query CheckIsLoggedInWhenInDashboardIndex {
    checkIsLoggedIn {
      message
      success
    }
  }
`

const DashboardIndex: FC = () => {
  const checkIsLoggedInResult = useQuery<CheckIsLoggedInWhenInDashboardIndexQuery, CheckIsLoggedInWhenInDashboardIndexQueryVariables>(checkIsLoggedInQuery)
  const isLoggedIn = !!checkIsLoggedInResult.data?.checkIsLoggedIn?.success

  useEffect(() => {
    if (!checkIsLoggedInResult.loading && !isLoggedIn) {
      navigate('/login', {
        replace: true
      })
    }
  }, [checkIsLoggedInResult.loading, isLoggedIn])

  if (checkIsLoggedInResult.loading || !isLoggedIn) {
    return <FullScreenSpin />
  }

  return (
    <DashboardLayout>
      <Router basepath='/dashboard'>
        <CaseList path='/' />
        <CaseList path='/cases' />
        <CaseCreate path='/cases/create' />
        <CasePage path='/cases/:id' />
        <DoctorCreate path='/doctors/create' />
        <DoctorList path='/doctors' />
        <DoctorPage path='/doctors/:id' />
        <NewsList path='/news' />
        <NewsCreate path='/news/create' />
        <NewsPage path='/news/:id' />
        <BannerCreate path='/banners/create' />
        <BannerList path='/banners' />
        <BannerPage path='/banners/:id' />
        <CommentList path='/comments' />
      </Router>
      {/** 多語言的 client only route 需要定義另外的 route */}
      <Router basepath='/:locale/dashboard'>
        <CaseList path='/' />
        <CaseList path='/cases' />
        <CaseCreate path='/cases/create' />
        <CasePage path='/cases/:id' />
        <DoctorCreate path='/doctors/create' />
        <DoctorList path='/doctors' />
        <DoctorPage path='/doctors/:id' />
        <NewsList path='/news' />
        <NewsCreate path='/news/create' />
        <NewsPage path='/news/:id' />
        <CommentList path='/comments' />
      </Router>
    </DashboardLayout>
  )
}

export default DashboardIndex
