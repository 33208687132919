import { gql, useMutation } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { Button, Form, Space, Typography, message } from 'antd'
import { Link, navigate } from 'gatsby-plugin-intl'
import React, { FC } from 'react'

import BannerForm, { BannerFormFields } from '../../../components/forms/BannerForm'
import { CreateBannerMutation, CreateBannerMutationVariables } from '../../../types/types'
import { getPayloadFromFieldsValue } from './utils'

const createBanner = gql`
  mutation CreateBanner($payload: CreateBannersInput!) {
    createBanners(payload: $payload) {
      id
    }
  }
`

const BannerCreate: FC<RouteComponentProps> = () => {
  const [form] = Form.useForm<BannerFormFields>()
  const [create] = useMutation<CreateBannerMutation, CreateBannerMutationVariables>(createBanner)

  const handleCreate = async () => {
    try {
      const fieldsValue = await form.validateFields()

      await create({
        variables: {
          payload: getPayloadFromFieldsValue(fieldsValue as BannerFormFields)
        },
        update: async (cache, { data }) => {
          if (data?.createBanners) {
            message.success(`已新增橫幅`)
            navigate('/dashboard/banners')
          }
        }
      })
    } catch (e) {
      if (e.errorFields) {
        form.scrollToField(e.errorFields[0].name)
      }

      if (e.message) {
        message.error(e.message)
      }
    }
  }

  return (
    <>
      <Typography.Title>新增橫幅</Typography.Title>
      <BannerForm form={form} />
      <Space>
        <Button type='primary' onClick={handleCreate}>
          新增
        </Button>
        <Link to='/dashboard/banners'>
          <Button>回列表</Button>
        </Link>
      </Space>
    </>
  )
}

export default BannerCreate
