import { UploadOutlined, ExclamationCircleTwoTone } from '@ant-design/icons'

import { Button, Form, Input, InputNumber, Switch, Tooltip } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { UploadFile } from 'antd/lib/upload/interface'
import React, { FC } from 'react'
import styled from 'styled-components'

import { takeLastFromFileList } from '../../utils/antd'
import GqlUpload from '../GqlUpload'

export interface BannerFormFields {
  image: UploadFile[]
  link?: string
  description?: string
  active?: boolean
  order?: number
}

interface Props {
  form: FormInstance<BannerFormFields>
  initialValues?: BannerFormFields
}

const LabelContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`

const BannerForm: FC<Props> = props => {
  const { form, initialValues } = props

  return (
    <Form form={form} initialValues={initialValues} layout='vertical' scrollToFirstError>
      <Form.Item
        name='image'
        valuePropName='fileList'
        getValueFromEvent={takeLastFromFileList}
        rules={[{ required: true, message: '必填欄位' }]}
        label={
          <LabelContainer>
            橫幅圖片&nbsp;
            <Tooltip placement='right' title='注意！圖片尺寸必須為1280 x 464像素，不然會版面會跑掉'>
              <ExclamationCircleTwoTone twoToneColor='#ff5900' style={{ fontSize: '13px' }} />
            </Tooltip>
          </LabelContainer>
        }
      >
        <GqlUpload listType='picture'>
          <Button>
            <UploadOutlined /> 上傳橫幅圖片
          </Button>
        </GqlUpload>
      </Form.Item>
      <Form.Item name='description' label='圖片說明'>
        <Input />
      </Form.Item>
      <Form.Item name='link' label='連結頁面'>
        <Input />
      </Form.Item>
      <Form.Item name='active' label='啟用'>
        <Switch defaultChecked={initialValues?.active} />
      </Form.Item>
      <Form.Item name='order' label='排列順序'>
        <InputNumber />
      </Form.Item>
    </Form>
  )
}

export default BannerForm
