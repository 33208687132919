import { Layout, Menu } from 'antd'
import { Link } from 'gatsby-plugin-intl'
import React, { FC } from 'react'
import styled from 'styled-components'

import { space } from '../../constants/length'
import LogoutButton from '../LogoutButton'

const Content = styled(Layout.Content)`
  padding: ${space.m}px;
`

const DashboardLayout: FC = props => {
  return (
    <Layout>
      <Layout.Header>
        <Menu theme='dark' mode='horizontal'>
          <Menu.Item key='logo'>
            <Link to='/dashboard'>Sunlight 網站後台</Link>
          </Menu.Item>
          <Menu.SubMenu key='cases' title='口碑見證'>
            <Menu.Item key='cases-list'>
              <Link to='/dashboard/cases'>列表</Link>
            </Menu.Item>
            <Menu.Item key='cases-create'>
              <Link to='/dashboard/cases/create'>新增</Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu key='news' title='最新衛教'>
            <Menu.Item key='news-list'>
              <Link to='/dashboard/news'>列表</Link>
            </Menu.Item>
            <Menu.Item key='news-create'>
              <Link to='/dashboard/news/create'>新增</Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu key='doctors' title='醫療團隊'>
            <Menu.Item key='doctors-list'>
              <Link to='/dashboard/doctors'>列表</Link>
            </Menu.Item>
            <Menu.Item key='doctors-create'>
              <Link to='/dashboard/doctors/create'>新增</Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item key='contactUs'>
            <Link to='/dashboard/comments'>聯絡我們</Link>
          </Menu.Item>
          <Menu.SubMenu key='banners' title='首頁橫幅'>
            <Menu.Item key='banners-list'>
              <Link to='/dashboard/banners'>列表</Link>
            </Menu.Item>
            <Menu.Item key='banners-create'>
              <Link to='/dashboard/banners/create'>新增</Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item key='logout' danger>
            <LogoutButton />
          </Menu.Item>
        </Menu>
      </Layout.Header>
      <Content>{props.children}</Content>
    </Layout>
  )
}

export default DashboardLayout
