import { gql, useMutation, useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { Button, Form, Popconfirm, Skeleton, Space, Typography, message } from 'antd'
import { Link, navigate } from 'gatsby-plugin-intl'
import moment from 'moment'
import { isNil } from 'ramda'
import React, { FC } from 'react'
import styled from 'styled-components'

import NewsForm, { NewsFormFields } from '../../../components/forms/NewsForm'
import {
  DashboardNewsQuery,
  DashboardNewsQueryVariables,
  RemoveNewsMutation,
  RemoveNewsMutationVariables,
  UpdateNewsMutation,
  UpdateNewsMutationVariables
} from '../../../types/types'
import { getFileObjectFromUrl } from '../../../utils/antd'
import { getPayloadFromFieldsValue } from './utils'

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
`

type Props = RouteComponentProps<{ id: string }>

const newsQuery = gql`
  query DashboardNews($id: ID!) {
    news(id: $id) {
      id
      category
      title
      alias
      pageTitle
      description
      content
      publishedDate
      images
      thumbnailImage
      thumbnailAlt
      video
    }
  }
`

const updateNews = gql`
  mutation UpdateNews($id: ID!, $payload: UpdateNewsInput!) {
    updateNews(id: $id, payload: $payload) {
      id
    }
  }
`

const removeNews = gql`
  mutation RemoveNews($id: ID!) {
    removeNews(id: $id) {
      id
    }
  }
`

const NewsPage: FC<Props> = props => {
  const { id: newsId } = props

  if (isNil(newsId)) {
    message.error('無法取得 ID')
    return (
      <Link to='/dashboard/news'>
        <Button>回列表</Button>
      </Link>
    )
  }

  const { data, loading, refetch } = useQuery<DashboardNewsQuery, DashboardNewsQueryVariables>(newsQuery, {
    variables: {
      id: newsId
    }
  })

  const [update] = useMutation<UpdateNewsMutation, UpdateNewsMutationVariables>(updateNews)
  const [remove] = useMutation<RemoveNewsMutation, RemoveNewsMutationVariables>(removeNews)
  const [form] = Form.useForm<NewsFormFields>()
  const newsData = data?.news

  if (loading) {
    return <Skeleton active />
  }
  if (isNil(newsData)) {
    message.error('查無資料，請返回列表頁')
    return (
      <Link to='/dashboard/news'>
        <Button>回列表</Button>
      </Link>
    )
  }

  const initialValues = {
    publishedDate: moment(newsData.publishedDate),
    title: newsData.title,
    pageTitle: newsData.pageTitle,
    description: newsData.description,
    alias: newsData.alias,
    category: newsData.category,
    content: newsData.content,
    video: newsData.video,
    images: newsData.images?.map((image, index) => getFileObjectFromUrl(image, { uid: `image-${index}`, name: `圖片${index + 1}` })),
    thumbnailImage: [getFileObjectFromUrl(newsData.thumbnailImage || '', { uid: newsId, name: newsData.title })],
    thumbnailAlt: newsData.thumbnailAlt
  }

  const handleUpdate = async () => {
    try {
      const fieldsValue = await form.validateFields()
      await update({
        variables: {
          id: newsId,
          payload: getPayloadFromFieldsValue(fieldsValue as NewsFormFields)
        },
        update: async (cache, { data }) => {
          if (data?.updateNews) {
            message.info('已更新衛教 \u{1F9DA}')
            refetch()
            navigate('/dashboard/news')
          } else {
            message.error('Oop！衛教網址重複 \u{1F925}')
          }
        }
      })
    } catch (e) {
      if (e.errorFields) {
        form.scrollToField(e.errorFields[0].name)
      }

      if (e.message) {
        message.error(e.message)
      }
    }
  }

  const handleRemove = async () => {
    try {
      await remove({
        variables: { id: newsId },
        update: async (cache, { data }) => {
          if (data?.removeNews) {
            message.info('已刪除衛教')
            navigate('/dashboard/news')
          }
        }
      })
    } catch (e) {
      if (e.message) {
        message.error(e.message)
      }
    }
  }

  return (
    <>
      <Typography.Title>編輯衛教</Typography.Title>
      {loading || isNil(newsData) ? (
        <Skeleton active />
      ) : (
        <>
          <NewsForm form={form} initialValues={initialValues} />
          <ButtonRow>
            <Space>
              <Button type='primary' onClick={handleUpdate}>
                更新
              </Button>
              <Link to='/dashboard/news'>
                <Button>回列表</Button>
              </Link>
            </Space>
            <Popconfirm title='確定刪除衛教？' onConfirm={handleRemove}>
              <Button type='primary' danger>
                刪除
              </Button>
            </Popconfirm>
          </ButtonRow>
        </>
      )}
    </>
  )
}

export default NewsPage
